import React, { useContext, useState } from "react";
import {
  Accordion,
  AccordionItem,
  Container,
  Stack,
  Card,
  ListGroup,
} from "react-bootstrap";
import { MdSegment } from "react-icons/md";
import LoggedOut from "../../universal/LoggedOut";
import ChangePrefCOGID from "./ChangePrefCOGID";
// import ChangeEmail from "./ChangeEmail";
import ChangePassword from "./ChangePassword";
import ChangeAdvancedOptions from "./ChangeAdvancedOptions";
import { useOutletContext } from "react-router-dom";
import MfaSetupPage from "./ChangeMFA";
import { AttributeContext } from "../../universal/Attributes";
import { AccountContext } from "../../universal/Account";

const UserDashboard = (props) => {
  const [loggedIn] = useOutletContext();
  const [accordionItem, setAccordionItem] = useState("");

  const { pullAttributes } = useContext(AccountContext);

  pullAttributes(); // Call pullAttributes to get the latest user attributes

  const Attributes = useContext(AttributeContext);
  const { cogid, stagingFlag, email, mfa } = Attributes;


  const handleAccordionClick = (eventKey) => {
    // check if eventkey matches acordionitem, if so, close it
    if (accordionItem === eventKey) {
      setAccordionItem("");
      return;
    } else {
      setAccordionItem(eventKey);
    }
  };



  return (
    <>
      {loggedIn ? (
        <>
          <Container fluid className="user-dashboard-container">
            {/* If MFA is not enabled, display a warning message */}
            {mfa === undefined ? (
              <>
                <Card bg="warning" className="user-dashboard-card">
                  <Card.Body>
                    <Card.Header style={{ color: "#dc0000" }}>
                      <h2>Multi-Factor Authentication is Required</h2>
                    </Card.Header>
                    <Card.Title>
                      <strong>
                        You must enable Multi-Factor Authentication(MFA) on your
                        account before accessing any applications.
                      </strong>
                    </Card.Title>
                    <Card.Text>
                      Please enable MFA on your account using the interface
                      below.
                      <br />
                      We support software MFA, which includes mobile apps such
                      as Authy, Google Authenticator, and Microsoft
                      Authenticator.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </>
            ) : mfa ===
              "SOFTWARE_TOKEN_MFA" ? // If MFA is enabled, don't display anything
            null : (
              <>
                <Card bg="warning" className="user-dashboard-card">
                  <Card.Body>
                    <Card.Header>
                      <h2>MFA WARNING</h2>
                    </Card.Header>
                    <Card.Title>
                      <strong>
                        You must enable MFA on your account before you can
                        access any applications.
                      </strong>
                    </Card.Title>
                    <Card.Text>
                      Please enable MFA on your account using the interface
                      below.
                      <br />
                      We support software MFA, which includes mobile apps such
                      as Authy, Google Authenticator, and Microsoft
                      Authenticator.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </>
            )}

            <Card className="user-dashboard-card">
              <Card.Body>
                <Card.Title>
                  <strong>Account Information</strong>
                </Card.Title>
                <Card.Text>
                  Review this information for accuracy. If you need to make any
                  changes, please use the options below.
                </Card.Text>
              </Card.Body>
              <ListGroup className="list-group-flush">
                <ListGroup.Item>
                  <strong>Account E-Mail:</strong> {email}
                </ListGroup.Item>
                {cogid === "" ? null : (
                  <ListGroup.Item
                    action
                    onClick={() => handleAccordionClick("0")}
                  >
                    <strong>Preferred COG ID :</strong> {cogid}
                  </ListGroup.Item>
                )}
                <ListGroup.Item
                  action
                  onClick={() => handleAccordionClick("4")}
                >
                  <strong>Staging Access :</strong>{" "}
                  {stagingFlag === "1" ? "Enabled" : "Disabled"}
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={() => handleAccordionClick("3")}
                  variant={
                    mfa === undefined
                      ? "danger"
                      : mfa === "SOFTWARE_TOKEN_MFA"
                      ? "success"
                      : "danger"
                  }
                >
                  <strong>MFA Status :</strong>{" "}
                  {mfa === undefined
                    ? "Disabled"
                    : mfa === "SOFTWARE_TOKEN_MFA"
                    ? "Enabled"
                    : "Disabled"}
                </ListGroup.Item>
              </ListGroup>
            </Card>
            <hr />
            <Accordion activeKey={accordionItem} flush className="">
              <Accordion.Item eventKey="0">
                <Accordion.Header onClick={() => handleAccordionClick("0")}>
                  <h4>
                    <MdSegment /> Change COG ID Preference
                  </h4>
                </Accordion.Header>
                <Accordion.Body>
                  <Stack gap={2} direction="horizontal">
                    <ChangePrefCOGID cogid={cogid} />
                  </Stack>
                </Accordion.Body>
              </Accordion.Item>
              {/* <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <h4>
                    <MdSegment /> Change Account Email
                  </h4>
                </Accordion.Header>
                <Accordion.Body>
                  <ChangeEmail />
                </Accordion.Body>
              </Accordion.Item> */}
              <Accordion.Item eventKey="2">
                <Accordion.Header onClick={() => handleAccordionClick("2")}>
                  <h4>
                    <MdSegment /> Change Account Password
                  </h4>
                </Accordion.Header>
                <Accordion.Body>
                  <ChangePassword />
                </Accordion.Body>
              </Accordion.Item>
              <AccordionItem eventKey="3">
                <Accordion.Header onClick={() => handleAccordionClick("3")}>
                  <h4>
                    <MdSegment /> Multi-Factor Authentication
                  </h4>
                </Accordion.Header>
                <Accordion.Body>
                  <Stack gap={2} direction="horizontal">
                    <MfaSetupPage />
                  </Stack>
                </Accordion.Body>
              </AccordionItem>
              <Accordion.Item eventKey="4">
                <Accordion.Header onClick={() => handleAccordionClick("4")}>
                  <h4>
                    <MdSegment /> Advanced Options
                  </h4>
                </Accordion.Header>
                <Accordion.Body>
                  <Stack gap={2} direction="horizontal">
                    <ChangeAdvancedOptions />
                  </Stack>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Container>
        </>
      ) : loggedIn === false ? (
        <LoggedOut />
      ) : null}
    </>
  );
};

export default UserDashboard;
