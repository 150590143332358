import React, { useState } from "react";
import UserPool from "../../../UserPool";
import LegalNotice from "../../universal/LegalNotice";
import Margin from "../../universal/Margin";
import { Button, Form, FloatingLabel, Alert, Stack } from "react-bootstrap";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [cogid, setCogid] = useState("");
  const [cogidCheckbox, setCogidCheckbox] = useState(false);

  const [signupAlert, setSignupAlert] = useState(false);
  const [signupAlertType, setSignupAlertType] = useState("danger");
  const [signupAlertMsg, setSignupAlertMsg] = useState("");
  const [signupAlertMsgSubtitle, setSignupAlertMsgSubtitle] = useState("");

  const [signupDisabled, setSignupDisabled] = useState(true);
  const [signupButtonVariant, setSignupButtonVariant] = useState("warning");
  const [EmailValidated, setEmailValidated] = useState(false);
  const [PasswordValidated, setPasswordValidated] = useState(false);
  const [CogidValidated, setCogidValidated] = useState(false);
  const [PasswordConfirmation, setPasswordConfirmation] = useState(false);
  const [PasswordConfirmMessage, setPasswordConfirmMessage] = useState(
    "Please ensure that your password matches the password above."
  );
  const passwordRef = React.useRef();

  const onSubmit = (event) => {
    event.preventDefault();

    // (method) CognitoUserPool.signUp(username: string, password: string, userAttributes: CognitoUserAttribute[], validationData: CognitoUserAttribute[], callback: NodeCallback<Error, ISignUpResult>, clientMetadata?: ClientMetadata): void
    UserPool.signUp(
      email,
      password,
      [
        {
          Name: "updated_at",
          Value: JSON.stringify(Date.now()),
        },
        {
          Name: "custom:prefferedcogid",
          Value: cogid,
        },
        {
          Name: "custom:staging",
          Value: "0",
        },
      ],
      null,
      (err, data) => {
        if (err) {
          // console.error(err);
          setSignupAlert(true);
          setSignupAlertMsg(err.message);
          setSignupAlertMsgSubtitle(
            "If you believe this is in error, please contact the administrator."
          );
          setSignupAlertType("danger");
        } else {
          // console.log(data);
          setSignupAlert(true);
          setSignupAlertMsg("Success!");
          setSignupAlertMsgSubtitle(
            "Please check your email for a verification code. Please allow a few minutes for the email to arrive."
          );
          setSignupAlertType("success");
        }
        // console.log("Outside if/else statement: " + data);
      }
    );
  };

  // This uses RegEx to validate the email address format. it then sets the state of the email address and the email validation.
  const isEmail = (value) => {
    var re = {
      emailFormat: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    };
    setEmail(value);
    var newValue = re.emailFormat.test(value);
    setEmailValidated(re.emailFormat.test(value));
    // console.log("Email Validation: " + (EmailValidated))
    setSignupDisabled(!EmailValidated);
    // console.log("From Email Validation - loginButtonDisabled: "+(!EmailValidated))
    signUpValidation(
      newValue,
      PasswordValidated,
      PasswordConfirmation
    );
  };

  // This uses RegEx to validate the COG ID format. it then sets the state of the COG ID value and the COG ID validation.
  const COGIDValidation = (value) => {
    setCogid(value);
    var re = {
      length: /(?=^[0-9]{6}$)/,
    };
    // var newValue = re.length.test(value);
    setCogidValidated(re.length.test(value));
    //console.log("COGID Validation: " + re.length.test(value));
    signUpValidation(
      EmailValidated,
      PasswordValidated,
      PasswordConfirmation
    );
  };

  // This uses RegEx to validate the password format. it then sets the state of the password and the password validation.
  const passwordValidation = (value, value2) => {
    // setPasswordConfirm(value2);
    setPassword(value);
    var re = {
      lowercase: /(?=.*[a-z])/,
      capital: /(?=.*[A-Z])/,
      length: /(?=.{8,99}$)/,
      // eslint-disable-next-line
      specialChar: /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
      digit: /(?=.*[0-9])/,
    };
    // var newValue =
    //   re.lowercase.test(value) &&
    //   re.capital.test(value) &&
    //   re.length.test(value) &&
    //   re.specialChar.test(value) &&
    //   re.digit.test(value) &&
    //   password !== "";
    setPasswordValidated(
      re.lowercase.test(value) &&
        re.capital.test(value) &&
        re.length.test(value) &&
        re.specialChar.test(value) &&
        re.digit.test(value) &&
        password !== ""
    );

    setPasswordConfirmMessage(
      value === passwordConfirm
        ? ""
        : " Please ensure that your password matches the password above."
    );

    // console.log("From PasswordValidated: " + PasswordValidated);
  };

  // This uses RegEx to validate the password format. it then sets the state of the password and the password validation.
  const passwordConfirmation = (value, value2) => {
    var newValue = value2 === value;
    setPasswordConfirmation(value2 === value);
    // console.log("From passwordConfirmation: " + newValue);
    signUpValidation(
      EmailValidated,
      PasswordValidated,
      newValue
    );
  };

  const signUpValidation = (data1, data2, data3) => {
    setSignupDisabled(!(data1 && data2 && data3));
    // console.log("Signup Validation: " + (data1 && data2 && data3 && data4));
    // console.log("Email Validated: "+data1)
    // console.log("Password Validated: "+data2)
    // console.log("COGID Validated: "+data4)
    // console.log("Password Confirmation: "+data3)
    setSignupButtonVariant(
      !(data1 && data2 && data3) ? "warning" : "primary"
    );
  };

  return (
    <>
      <>
        <h2 className="login-title">
          IPAWS Alerting Authority
          <br />
          ATP(Assistive Tool Platform)
          <br />
          Sign Up
        </h2>
        <Alert
          className="loginAlert"
          show={signupAlert}
          onClose={() => setSignupAlert(false)}
          variant={signupAlertType}
          dismissible
        >
          <Alert.Heading>{signupAlertMsg}</Alert.Heading>
          <p>{signupAlertMsgSubtitle}</p>
        </Alert>
        <Form className="login-form" onSubmit={onSubmit}>
          <Stack gap={3}>
            <Form.Group className="mb-3" controlId="formBasicEmailSignup">
              <FloatingLabel
                controlId="floatingInputEmailSignup"
                label="Email address"
                className="mb-3"
              >
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  autoComplete="email"
                  value={email}
                  onChange={(event) => isEmail(event.target.value)}
                  style={{ height: "50px" }}
                />
              </FloatingLabel>
              {EmailValidated ? (
                ""
              ) : (
                <Form.Text id="passwordHelpBlock" muted>
                  Your E-Mail must be properly formatted.
                  <br />
                  If the 'Change Account E-Mail' button is yellow, your E-Mail
                  is not properly formatted.
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPasswordSignup">
              <FloatingLabel
                controlId="floatingInputPasswordSignup"
                label="Password"
                className="mb-3"
              >
                <Form.Control
                  ref={passwordRef}
                  type="password"
                  placeholder="Password"
                  autoComplete="new-password"
                  value={password}
                  onChange={(event) => {
                    passwordValidation(event.target.value, "");
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      passwordValidation(passwordRef.current.value);
                    }, 0);
                  }}
                  style={{ height: "50px" }}
                />
              </FloatingLabel>
              {PasswordValidated ? (
                ""
              ) : (
                <Form.Text id="PasswordMatchHelpBlock" muted>
                  Your password must meet these requirements: 8+ characters
                  long, 1 uppercase letter, 1 lowercase letter, 1 special
                  character, and 1 number.
                </Form.Text>
              )}
              {passwordConfirmation ? (
                ""
              ) : (
                <>
                  <br />
                  <Form.Text id="COGIDHelpBlock" muted>
                    {PasswordConfirmMessage}
                  </Form.Text>
                </>
              )}
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="formBasicPasswordConfirmSignup"
            >
              <FloatingLabel
                controlId="floatingInputPasswordConfirmSignup"
                label="Confirm Password"
                className="mb-3"
              >
                <Form.Control
                  type="password"
                  placeholder="Confirm Password"
                  autoComplete="new-password"
                  value={passwordConfirm}
                  onChange={(event) => {
                    passwordConfirmation(event.target.value, password);
                    setPasswordConfirm(event.target.value);
                  }}
                  style={{ height: "50px" }}
                />
              </FloatingLabel>
              {PasswordConfirmation ? (
                ""
              ) : (
                <Form.Text id="COGIDHelpBlock" muted>
                  Please ensure that your password matches the password above.
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCogidSignup">
              <Form.Check // prettier-ignore
              type='switch'
              id={`default-checkbox`}
              label={`Is your account going to associated with a COG ID?`}
              onChange={(event) => {
                if (event.target.checked || !event.target.checked) {
                  setCogidCheckbox(!cogidCheckbox);
                }
              }}
            />
            <br/>
            {cogidCheckbox ? (
              <>
              <FloatingLabel
              controlId="floatingInputCOGIDSignup"
              label="COG ID"
              className="mb-3"
            >
              <Form.Control
                type="number"
                placeholder="000000"
                value={cogid}
                onChange={(event) => COGIDValidation(event.target.value)}
                style={{ height: "50px" }}
              />
            </FloatingLabel>
            {CogidValidated ? (
                ""
              ) : (
                <Form.Text id="COGIDHelpBlock" muted>
                  The COG ID enter must be a six digit number. Ex: ######
                </Form.Text>
              )}
              </>
            ) : (
              "" 
            )}
              
            </Form.Group>
          </Stack>

          <Stack gap={3}>
            <Button
              variant={signupButtonVariant}
              type="submit"
              size="lg"
              disabled={signupDisabled}
            >
              Sign Up
            </Button>
          </Stack>
          <Margin height={1.5} />
        </Form>
        <LegalNotice />
      </>
    </>
  );
};

export default Signup;
