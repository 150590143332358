import React from 'react';
import PropTypes from 'prop-types';

const Margin = ({ height }) => {
  return (
    <div style={{ width: '100%', height: `${height}rem` }} />
  );
}

Margin.propTypes = {
  height: PropTypes.number.isRequired,
};

export default Margin;
