import React, { useState, useContext, useEffect, useRef } from "react";
import { AccountContext } from "../../universal/Account";
import LegalNotice from "../../universal/LegalNotice";
import {
  Button,
  Form,
  FloatingLabel,
  Alert,
  Modal,
  Stack,
} from "react-bootstrap";

const Login = () => {
  //Login field states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [EmailValidated, setEmailValidated] = useState(false);
  const [loginButtonDisabled, setloginButtonDisabled] = useState(true);
  const emailField = useRef(null);

  // const [isMFARequired, setIsMFARequired] = useState(false);
  const [mfaCode, setMfaCode] = useState("");
  const [MFAAlert, setMFAAlert] = useState(false);
  const [MFAAlertMsg, setMFAAlertMsg] = useState("");

  // Wrong Password Alerts
  const [LoginAlert, setLoginAlert] = useState(false);
  const [loginAlertMsg, setLoginAlertMsg] = useState("");
  const [loginAlertMsgSubtitle, setLoginAlertMsgSubtitle] = useState("");
  const [loginAlertType, setloginAlertType] = useState("danger");

  // Modal controls
  const [modalFPW, setModalFPW] = useState(false);
  const handleCloseFPW = () => {
    setModalFPW(false);
    //console.log(modalFPW);
  };
  const handleShowFPW = () => {
    setModalFPW(true);
    //console.log(modalFPW);
  };

  const { authenticate, resetPassword, sendMfaCode, isMfaRequired, userData } =
    useContext(AccountContext);

  const onSubmit = (event) => {
    event.preventDefault();

    authenticate(email, password)
      .then((data) => {
        //console.log("Logged in!", data);
      })
      .catch((err) => {
        console.error("Failed to login: ", err);
        //console.log("Show before:", LoginAlert)
        setLoginAlert(true);
        setLoginAlertMsg(err.message);
        setLoginAlertMsgSubtitle("Please try again.");
        setloginAlertType("danger");
        //setLoginToast(true)
        //console.log("Show after:", LoginAlert)
      });
  };

  const handleMfaCodeSubmit = (event) => {
    event.preventDefault();
    
    sendMfaCode(mfaCode, userData)
      .then((data) => {
        // console.log("MFA code verification was successful!", data);
        // You can redirect the user to the dashboard or another page here
      })
      .catch((err) => {
        console.error("Failed to verify MFA code: ", err);
        setMFAAlertMsg(err.message);
        setMFAAlert(true);

        // You can show an error message to the user here
      });
  };

  const onReset = (event) => {
    event.preventDefault();

    resetPassword(email)
      .then((data) => {
        handleCloseFPW();
        alert("Your password has been reset.");
      })
      .catch((err) => {
        console.error("Failed to reset password: ", err);
        alert("Failed to reset password: ", err);
      });
    handleCloseFPW();
  };

  // This uses RegEx to validate the email address format. it then sets the state of the email address and the email validation.
  const isEmail = (value) => {
    var re = {
      emailFormat: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    };
    setEmail(value);
    setEmailValidated(re.emailFormat.test(value));
    // console.log("Email Validation: " + (EmailValidated))
    setloginButtonDisabled(!EmailValidated);
    // console.log("From Email Validation - loginButtonDisabled: "+(!EmailValidated))
  };

  //This was implemented to account for brower autofill. It checks the email field every 100ms to see if it has been filled in, once it has it runs the isEmail function, and stops the interval.
  useEffect(() => {
    let interval = setInterval(() => {
      if (emailField.current) {
        isEmail(emailField.current.value);
        //console.log(emailField.current.value)
        //do the same for all autofilled fields
        clearInterval(interval);
      }
    }, 100);
  });

  return (
    <>
      {!isMfaRequired ? (
        <>
          {/* Login Title */}
          <h2 className="login-title">
            IPAWS Alerting Authority
            <br />
            ATP(Assistive Tool Platform)
            <br />
            Login
          </h2>

          {/* Login Form */}
          <Form className="login-form" noValidate onSubmit={onSubmit}>
            {/* Input Field Stack */}
            <Stack gap={3}>
              <Form.Group className="mb-3" controlId="formBasicEmailLogin">
                <FloatingLabel
                  controlId="floatingInputEmailSignUp"
                  label="Email address"
                  className="mb-2"
                >
                  <Form.Control
                    ref={emailField}
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(event) => isEmail(event.target.value)}
                    style={{ height: "50px" }}
                  />
                </FloatingLabel>
                <Form.Text id="passwordHelpBlock" muted>
                  {loginButtonDisabled === true
                    ? "Your E-Mail must be properly formatted."
                    : ""}
                  <br />
                  {loginButtonDisabled === true
                    ? "If the 'Change Account E-Mail' button is yellow, your E-Mail is not properly formatted."
                    : ""}
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPasswordLogin">
                <FloatingLabel
                  controlId="floatingInputPasswordLogin"
                  label="Password"
                  className="mb-3"
                >
                  <Form.Control
                    className="ms-auto"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    style={{ height: "50px" }}
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="d-grid gap-2">
                <Button
                  variant={!loginButtonDisabled ? "primary" : "warning"}
                  type="submit"
                  disabled={loginButtonDisabled ? true : false}
                  size="lg"
                >
                  Login
                </Button>
              </Form.Group>

              <Button
                className="mb-3"
                variant="danger"
                size=""
                onClick={handleShowFPW}
              >
                Forgot Password?
              </Button>
            </Stack>
          </Form>

          {/* Login Alert */}
          <Alert
            className="loginAlert"
            show={LoginAlert}
            onClose={() => setLoginAlert(false)}
            variant={loginAlertType}
            dismissible
          >
            <Alert.Heading>{loginAlertMsg}</Alert.Heading>
            <p>{loginAlertMsgSubtitle}</p>
          </Alert>

          <LegalNotice />

          {/*PasswordResetModal*/}
          <Modal
            className="in"
            show={modalFPW}
            centered
            onHide={handleCloseFPW}
          >
            <Modal.Header closeButton>
              <Modal.Title>Forgot password?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Enter your email to reset your password
              <Form.Group className="mb-3" controlId="formBasicEmailReset">
                <FloatingLabel
                  controlId="floatingInputEmailReset"
                  label="Email address"
                  className="mb-2"
                >
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    style={{ height: "50px" }}
                  />
                </FloatingLabel>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseFPW}>
                Close
              </Button>
              <Form.Group>
                <Button variant="danger" onClick={onReset}>
                  Reset Password
                </Button>
              </Form.Group>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <>
          {/* MFA Code Form */}
          <Form
            className="login-form"
            noValidate
            onSubmit={handleMfaCodeSubmit}
          >
            <Form.Group className="mb-3" controlId="formBasicMfaCode">
            <Form.Text id="passwordHelpBlock" muted>
                  Your Multi-Factor Authentication code can be found in the Authenticator app on your mobile device.
                </Form.Text>
              <FloatingLabel
                controlId="floatingInputEmailSignUp"
                label="Enter MFA Code"
                className="mb-2"
              >
                <Form.Control
                  type="number"
                  placeholder="Enter MFA code"
                  value={mfaCode}
                  onChange={(e) => setMfaCode(e.target.value)}
                />
              </FloatingLabel>
            </Form.Group>
            <Button
              variant={!loginButtonDisabled ? "primary" : "warning"}
              type="submit"
              disabled={loginButtonDisabled ? true : false}
            >
              Submit MFA Code
            </Button>
            <br />
            {/* MFA Alert */ }
          {MFAAlert === false ? null : (
          <Alert
            className="MFAAlert"
            show={MFAAlert}
            onClose={() => setMFAAlert(false)}
            variant="danger"
            dismissible
          >
            <Alert.Heading>Error</Alert.Heading>
            <p>{MFAAlertMsg}</p>
          </Alert>
          )
          }
          </Form>
        </>
      )}
    </>
  );
};
export default Login;
